import React from "react";
import CountUp from "react-countup";
import CellButton from "./CellButton";
import { calcRarity } from "../lib/utils/calcRarity";
import { TikTokButton } from "./buttons/TikTokButton";
import { SummaryButton } from "./buttons/SummaryButton";
import { HistoryButton } from "./buttons/HistoryButton";
import { GiveUpButton } from "./buttons/GiveUpButton";
import useGameStore from "../lib/store/GameStore";
import usePersistentStore from "../lib/store/PersistentStore";
import { shallow } from "zustand/shallow";
import { CategoryHeaderTooltip } from "./CategoryHeaders";
import { GameHistoryLauncher } from "./GameHistoryLauncher";

export function Board({ selectedTab }) {
  const [grid, globalStats] = useGameStore(
    (state) => [state.grid, state.globalStats],
    shallow
  );
  const [guessesLeft, correctGuesses, undoUsed] = usePersistentStore(
    (state) => [state.guessesLeft, state.correctGuesses, state.undoUsed],
    shallow
  );

  const rarity = calcRarity(correctGuesses, globalStats);
  const rarityDisplay = isNaN(rarity) ? 900 : rarity;
  const hideGrid = selectedTab == "popular" && guessesLeft > 0;

  return !grid ? (
    <></>
  ) : !hideGrid ? (
    <>
      <div className="flex justify-center">
        <div className="flex w-20 items-center justify-center sm:w-36 md:w-48">
          <GameHistoryLauncher grid={grid} />
        </div>
        <ColHeader item={grid.hItems[0]} selectedTab={selectedTab} />
        <ColHeader item={grid.hItems[1]} selectedTab={selectedTab} />
        <ColHeader item={grid.hItems[2]} selectedTab={selectedTab} />
        <div className="sm:w-36 md:w-48"></div>
      </div>
      <div className="flex items-center justify-center">
        <div>
          <RowHeader item={grid.vItems[0]} selectedTab={selectedTab} />
          <RowHeader item={grid.vItems[1]} selectedTab={selectedTab} />
          <RowHeader item={grid.vItems[2]} selectedTab={selectedTab} />
        </div>
        <div className="grid grid-cols-3 overflow-hidden rounded-xl border bg-white dark:border-gray-950 dark:bg-gray-800">
          <CellButton row={1} col={1} selectedTab={selectedTab} />
          <CellButton row={1} col={2} selectedTab={selectedTab} />
          <CellButton row={1} col={3} selectedTab={selectedTab} />
          <CellButton row={2} col={1} selectedTab={selectedTab} />
          <CellButton row={2} col={2} selectedTab={selectedTab} />
          <CellButton row={2} col={3} selectedTab={selectedTab} />
          <CellButton row={3} col={1} selectedTab={selectedTab} />
          <CellButton row={3} col={2} selectedTab={selectedTab} />
          <CellButton row={3} col={3} selectedTab={selectedTab} />
        </div>
        <div className="flex h-full justify-center sm:w-36 md:w-48">
          {selectedTab != "popular" && (
            <div className="mt-16 hidden sm:block">
              <div className="text-center text-sm font-semibold uppercase text-gray-600 dark:text-gray-300">
                {guessesLeft <= 0 ? "Rarity Score" : "Guesses Left"}
              </div>
              <div className="mb-2 text-center text-7xl font-semibold leading-none tracking-tight">
                {guessesLeft <= 0 ? (
                  <CountUpRarity rarity={rarityDisplay} />
                ) : (
                  <CountUpWrap number={guessesLeft} />
                )}
              </div>

              <div className={`mb-2 ${guessesLeft >= 9 && "invisible"}`}>
                {guessesLeft <= 0 ? <SummaryButton /> : <GiveUpButton />}
              </div>
              <div className={`mb-2 ${guessesLeft >= 9 && "invisible"}`}>
                <HistoryButton />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-end justify-center space-x-4 sm:hidden">
        {selectedTab == "popular" ? (
          <TikTokButton />
        ) : (
          <>
            <div className="mt-4 md:mt-8">
              <div className="text-center text-sm font-semibold uppercase text-gray-600 dark:text-gray-300">
                {guessesLeft <= 0 ? "Rarity Score" : "Guesses Left"}
              </div>
              <div className="flex items-center justify-center ">
                <div className={`${guessesLeft >= 9 && "invisible"}`}>
                  <HistoryButton />
                </div>
                <div className="relative min-w-[8rem] px-8 text-center text-6xl font-semibold leading-none tracking-tight sm:text-7xl">
                  {guessesLeft <= 0 ? (
                    <CountUpRarity rarity={rarityDisplay} />
                  ) : (
                    <CountUpWrap number={guessesLeft} />
                  )}
                </div>
                <div className={`${guessesLeft >= 9 && "invisible"}`}>
                  {guessesLeft <= 0 ? <SummaryButton /> : <GiveUpButton />}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {selectedTab != "popular" && (
        <div className="flex justify-center pt-2 text-sm sm:pt-6 sm:text-base">
          Tap on a category for more info
        </div>
      )}
    </>
  ) : (
    <div className="mt-12">
      <div className="flex justify-center text-xl font-semibold uppercase text-gray-700 dark:text-gray-200">
        Most Popular
      </div>
      <div className="flex justify-center py-4 text-gray-700 dark:text-gray-400">
        Complete today's grid to see.
      </div>
    </div>
  );
}

function UndoToggle() {
  const [undoModeActive, setUndoModeActive, setIsPickHistoryOpen] =
    useGameStore((state) => [
      state.undoModeActive,
      state.setUndoModeActive,
      state.setIsPickHistoryOpen,
    ]);

  function toggleUndo() {
    undoModeActive ? setUndoModeActive(false) : setUndoModeActive(true);
    setIsPickHistoryOpen(true);
  }

  return (
    <div className="block sm:hidden">
      <input
        onClick={toggleUndo}
        type="checkbox"
        id="undo-mode"
        className="peer hidden"
      />
      <label
        htmlFor="undo-mode"
        className="inline-flex cursor-pointer items-center rounded-full  border-2 border-gray-200 bg-white p-2 text-gray-800 hover:bg-gray-50 hover:text-gray-600 peer-checked:border-white peer-checked:bg-gray-700 peer-checked:text-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:peer-checked:text-gray-300 md:p-3"
      >
        <div className="block">
          <svg
            className="mr-px h-5 w-5 md:h-8 md:w-8"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 940 1000"
          >
            <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
          </svg>
        </div>
      </label>
    </div>
  );
}

function CountUpWrap({ number }) {
  return (
    <div className="countup-wrap">
      {number == 9 ? (
        <CountUp end={number} useEasing={false} duration={0.25} />
      ) : (
        number
      )}
    </div>
  );
}

export function CountUpRarity({ rarity }) {
  return (
    <div className="countup-wrap">
      <CountUp
        decimals={rarity < 100 ? 1 : 0}
        end={rarity}
        useEasing={false}
        duration={0.25}
      />
    </div>
  );
}

function ColHeader({ item, selectedTab }) {
  return (
    <div className="flex h-16 w-24 items-center justify-center text-xs sm:h-28 sm:w-36 md:h-36 md:w-48 ">
      <CategoryHeaderTooltip item={item} pos="col" selectedTab={selectedTab} />
    </div>
  );
}

function RowHeader({ item, selectedTab }) {
  return (
    <div className="flex h-24 w-20 items-center justify-center text-xs sm:h-36 sm:w-36 md:h-48 md:w-48 ">
      <CategoryHeaderTooltip item={item} pos="row" selectedTab={selectedTab} />
    </div>
  );
}
