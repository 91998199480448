import React from "react";
import { trackEvent } from "../../lib/utils/analytics";
import useGameStore from "../../lib/store/GameStore";

export function HistoryButton() {
  const [setIsPickHistoryOpen] = useGameStore((state) => [
    state.setIsPickHistoryOpen,
  ]);

  function openHistory() {
    setIsPickHistoryOpen(true);
    trackEvent("history_button");
  }

  return (
    <button
      onClick={openHistory}
      className="inline-flex cursor-pointer items-center rounded-full border-2 border-gray-200 bg-white p-2 text-xs font-medium text-gray-700 hover:bg-gray-50  hover:text-gray-600 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 sm:px-3"
    >
      <svg
        className="mr-px h-5 w-5 sm:mr-1.5 sm:h-4 md:w-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 940 1000"
      >
        <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
      </svg>
      <span className="hidden sm:inline-block">History</span>
    </button>
  );
}
