import useSWR, { mutate } from "swr";
import { gridLevelDateKey } from "../../lib/utils/gridLevels";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetcher = async (key) => {
  //   console.log(key);
  //   await sleep(2000);
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}${key}`;
  const r = await fetch(url, { credentials: "include" });

  return await r.json();
};

function invalidate() {
  mutate("/api/leaderboard/" + gridLevelDateKey);
}

export function useLeaderboard() {
  const { data, error, isLoading } = useSWR(
    "/api/leaderboard/" + gridLevelDateKey,
    fetcher,
    {
      revalidateOnFocus: false,
      dedupingInterval: 60000,
    }
  );

  return {
    leaderboard: data,
    isLoading,
    isError: error,
    invalidate: invalidate,
  };
}
