import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import { IconBarChart } from "../icons/IconBarChart";
import useGameStore from "../../lib/store/GameStore";

export function SummaryButton() {
  const [setIsOpen] = useGameStore((state) => [state.setIsResultOpen]);

  function openModal() {
    setIsOpen(true);
    trackEvent("summary_button");
    trackSelect({
      contentType: "button",
      itemId: "show_result",
    });
  }

  return (
    <button
      onClick={openModal}
      className="inline-flex cursor-pointer items-center rounded-full border-2 border-gray-200 bg-white p-2 text-xs font-medium text-gray-800 hover:bg-gray-50  hover:text-gray-600 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 sm:px-3"
    >
      <IconBarChart className="h-5 w-5 sm:mr-1.5 sm:h-4 md:w-4" />
      <span className="hidden sm:inline-block">Summary</span>
    </button>
  );
}
