import { Drawer } from "vaul";

export default function BaseBottomSheet({
  trigger,
  content,
  scrollable = true,
}) {
  return (
    <Drawer.Root>
      <Drawer.Trigger className="focus:outline-none" asChild>
        {trigger}
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content
          className={`fixed bottom-0 left-0 right-0 flex ${
            scrollable ? "max-h-[75%]" : "max-h-[95%]"
          }  flex-col rounded-t-[10px] bg-white focus:outline-none`}
        >
          <div className="mx-auto mb-4 mt-4 h-1.5 w-12 flex-shrink-0 rounded-full bg-zinc-300" />
          <div
            className={`mx-auto mb-2 flex w-full max-w-md flex-col standalone:mb-8 ${
              scrollable ? "overflow-y-auto" : ""
            } rounded-t-[10px]`}
          >
            {content}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
