import React from "react";
import useGameStore from "../../lib/store/GameStore";

export default function GameHistoryButton({ grid, onClick = null }) {
  const [isGameHistoryOpen] = useGameStore((state) => [
    state.isGameHistoryOpen,
  ]);

  return (
    <button
      onClick={onClick}
      className={`${
        isGameHistoryOpen ? "bg-white sm:border" : ""
      } transition-colos group flex flex-col items-center justify-center rounded-xl px-2.5 py-1.5 hover:bg-white sm:px-4  sm:py-3 sm:hover:border`}
    >
      <img
        className="mb-0.5 h-5 w-5 sm:mb-1 sm:h-7 sm:w-7 md:h-8 md:w-8"
        src="img/pokegrid-ball.png"
        alt="PokeGrid Logo"
      ></img>
      <div className="text-xxs font-semibold leading-tight text-gray-800 group-hover:text-black sm:text-md md:text-base">
        PokeGrid
      </div>
      <div className="flex items-center justify-center">
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          height="1em"
          width="1em"
          className="invisible h-3.5 w-3.5 text-gray-500 group-hover:text-black sm:-ml-0.5 sm:h-5 sm:w-5"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
        <div className="text-xs font-semibold leading-tight text-gray-800 group-hover:text-black sm:text-lg">
          #{grid.levelNumber}
        </div>
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          height="1em"
          width="1em"
          className="h-4 w-4 text-gray-500 group-hover:text-black sm:ml-0.5 sm:h-5 sm:w-5"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </button>
  );
}
