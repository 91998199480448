import { BaseDialog } from "./BaseDialog";
import { hasImage, pokemonList } from "../../lib/utils/pokemonList";
import { CategoryHeaderSmall } from "../CategoryHeaders";
import { rarityScheme } from "../../lib/utils/rarityScheme";
import ToolTip from "./ToolTip";
import usePersistentStore from "../../lib/store/PersistentStore";
import useGameStore from "../../lib/store/GameStore";
import { PokemonImage } from "../PokemonImage";
import { PokemonTypeImage } from "../PokemonTypeImage";

function PickDialog({ isOpen, closeModal, pokemon, selectedPct, row, col }) {
  const scheme = rarityScheme(selectedPct);

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={
        <div className="">
          {/* <div className="text-center text-2xl font-semibold uppercase dark:text-white">
            Pick
          </div> */}
        </div>
      }
      size="lg"
      dark={scheme.dark}
      modalBody={
        <ModalBody
          pokemon={pokemon}
          selectedPct={selectedPct}
          row={row}
          col={col}
        />
      }
    />
  );
}

function findPokemonById(pokemonId) {
  return pokemonList.find((pokemon) => pokemon.id === pokemonId);
}

function ModalBody({ pokemon, selectedPct, row, col }) {
  const [undoGuess, undoUsed] = usePersistentStore((state) => [
    state.undoGuess,
    state.undoUsed,
  ]);
  const [grid] = useGameStore((state) => [state.grid]);
  const hItem = grid.hItems[col - 1];
  const vItem = grid.vItems[row - 1];

  const scheme = rarityScheme(selectedPct);

  return (
    <div className="-mx-4 -mb-4 -mt-6 flex flex-col items-center justify-center ">
      <div
        className={`bg-fit flex h-40 w-full cursor-pointer items-center justify-center overflow-hidden ${scheme.class} bg-cover pt-4 focus-visible:z-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#59d185] dark:border-gray-950 dark:hover:bg-gray-700 sm:h-64 `}
      >
        <div className="flex items-center justify-between">
          {vItem.type == "pokemon-type" ? (
            <div className="flex w-16 justify-center sm:w-24">
              <PokemonTypeImage
                typeId={vItem.id}
                className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
              />
            </div>
          ) : (
            <div className="w-16 sm:w-24">
              <CategoryHeaderSmall item={vItem} />
            </div>
          )}

          <div className="overflow group relative flex h-full w-44 items-center justify-center sm:w-64">
            <PokemonImage pokemon={pokemon} className="p-4 sm:p-8" />
          </div>

          {hItem.type == "pokemon-type" ? (
            <div className="flex w-16 justify-center sm:w-24">
              <PokemonTypeImage
                typeId={hItem.id}
                className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
              />
            </div>
          ) : (
            <div className="w-16 sm:w-24">
              <CategoryHeaderSmall item={hItem} />
            </div>
          )}
        </div>
      </div>
      <div className="w-full border-y border-solid px-2.5 pt-6 text-center sm:pt-8">
        {scheme.word ? (
          <>
            <h2 className="mb-6 text-xl font-extrabold uppercase leading-tight  sm:mb-8 sm:text-2xl ">
              {pokemon.display_name}
            </h2>
            <h2 className="text-3xl font-extrabold uppercase leading-none tracking-wide sm:text-4xl">
              {scheme.emoji} {scheme.word} {scheme.emoji}
            </h2>
            <h3 className="mb-6 inline-flex items-center justify-center text-sm font-medium uppercase text-gray-700 sm:mb-8">
              {selectedPct.toFixed(1)}% Selected{" "}
              {scheme.toolTip && (
                <ToolTip
                  title={scheme.toolTip}
                  iconClassName="ml-1 h-3 w-3"
                  placement="bottom"
                />
              )}
            </h3>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-extrabold uppercase leading-tight sm:text-3xl">
              {pokemon.display_name}
            </h2>
            <h3 className="mb-6 text-sm font-medium uppercase sm:mb-8 sm:text-base">
              {selectedPct.toFixed(1)}% Selected
            </h3>
          </>
        )}

        {selectedPct < 0.1 ? (
          <p className="mb-8 px-4 text-md font-normal sm:mb-10 sm:text-lg">
            Congratulations - you found a{" "}
            <span className="font-bold">unique</span> pokemon!
          </p>
        ) : undoUsed ? (
          <div className="mb-2 sm:mb-4"></div>
        ) : (
          <>
            <p className="mb-5 px-4 text-md font-normal sm:mb-8 sm:text-lg">
              Think you can find rarer? Use your{" "}
              <span className="font-bold">daily undo</span> and select again.
            </p>
            <button
              onClick={() => undoGuess(pokemon, row, col)}
              className="relative mb-6 rounded bg-red-600 px-9 py-3 text-sm font-bold uppercase text-white sm:mb-8"
            >
              Undo Selection
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default PickDialog;
