import { isMobile } from "react-device-detect";
import { trackFriendCodeShare } from "./analytics";

export async function handleShareFriendCode(code) {
  share({ code });
}

function getShareText(code) {
  return `Let's be PokeGrid friends! My friend code is ${code}`;
}

export async function share({ code }) {
  if ("share" in navigator && isMobile) {
    const shareData = {
      text: getShareText(code),
    };

    try {
      await navigator.share(shareData);
      trackFriendCodeShare({ method: "Mobile" });
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      updateClipboard(code);
      trackFriendCodeShare({ method: "Copy" });
    } catch (err) {
      console.log(err);
    }
  }
}

function updateClipboard(newClip) {
  return navigator.clipboard.writeText(newClip);
}
