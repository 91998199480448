import React, { useState, useEffect } from "react";
import useGameStore from "../lib/store/GameStore";
import { trackEvent } from "../lib/utils/analytics";
import RecentGamesSheet from "./bottomSheets/RecentGamesSheet";
import GameHistoryButton from "./buttons/GameHistoryButton";

export function GameHistoryLauncher({ grid }) {
  const [setIsGameHistoryOpen] = useGameStore((state) => [
    state.setIsGameHistoryOpen,
  ]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function openHistory() {
    setIsGameHistoryOpen(true);
    trackEvent("archive_button");
  }
  return isMobile ? (
    <RecentGamesSheet grid={grid} />
  ) : (
    <GameHistoryButton grid={grid} onClick={openHistory} />
  );
}
