import { BaseDialog } from "./BaseDialog";
import usePersistentStore from "../../lib/store/PersistentStore";
import useGameStore from "../../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { pokemonList, hasImage } from "../../lib/utils/pokemonList";
import { rarityScheme } from "../../lib/utils/rarityScheme";
import { CountUpRarity } from "../Board";
import { calcRarity } from "../../lib/utils/calcRarity";
import { PokemonTypeImage } from "../PokemonTypeImage";
import { PokemonImage } from "../PokemonImage";
import { CategoryHeaderSmall } from "../CategoryHeaders";

function FriendDialog({ isOpen, closeModal, attempt, rank }) {
  return (
    <BaseDialog
      isOpen={isOpen}
      dark={true}
      closeModal={closeModal}
      modalBody={<ModalBody attempt={attempt} />}
    />
  );
}

function ModalBody({ attempt }) {
  const [guessesLeft] = usePersistentStore(
    (state) => [state.guessesLeft],
    shallow
  );

  const bg = "bg-gradient-to-r from-black/70 bg-cover";

  return (
    <div className="-mx-4 -mb-4 -mt-6 flex flex-col items-center justify-center bg-neutral-100 text-center">
      <div
        className={`bg-fit flex w-full cursor-pointer items-center justify-between overflow-hidden bg-blue-950/90 px-4 py-4 font-medium focus-visible:z-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#59d185] dark:border-gray-950 dark:hover:bg-gray-700 sm:px-6 sm:py-4`}
      >
        <div id="name" className="flex items-center text-gray-800">
          <PokemonTypeImage
            type={attempt.user.favType}
            alt={`${attempt.user.name} avatar`}
            className="mr-3 h-5 w-5 rounded-full outline outline-1 outline-white sm:mr-3 sm:h-6 sm:w-6"
          />

          <div className="flex flex-col">
            <div className="line-clamp-1 text-ellipsis text-left text-md font-semibold text-white antialiased sm:text-base">
              {attempt.user.displayName}
            </div>
            {/* <div className="line-clamp-1 text-ellipsis text-left text-xxs text-white/70 antialiased group-[.user-row]:text-slate-100 sm:text-xs">
              Joined Oct '23
            </div> */}
          </div>
        </div>
      </div>
      <DropHeader attempt={attempt} />
      <div className="mb-4 mt-2 flex min-h-[12rem] flex-col justify-center">
        {guessesLeft <= 0 ? (
          <FriendGrid attempt={attempt} />
        ) : (
          <div class="px-6">
            <div class="mb-4 flex justify-center text-lg font-semibold uppercase text-gray-700 dark:text-gray-200">
              View grid
            </div>
            <div class="mb-4 flex justify-center text-md text-gray-700 dark:text-gray-400">
              <p>Play todays grid, then see other's selections</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function DropHeader({ attempt }) {
  const bg = "normal";
  const [levelNumber] = useGameStore((state) => [state.levelNumber]);

  return (
    <>
      <div className={`h-0.5 w-full bg-blue-950/80`} />
      <div id="grid-number" className="flex items-center justify-center">
        <div id="left" className="text-blue-950/80">
          <TabLeft className="h-7 w-7 sm:h-8 sm:w-8" />
        </div>
        <div
          className={`flex h-7 items-center bg-blue-950/80 px-2 text-xs font-semibold uppercase tracking-wide text-white antialiased sm:h-8 sm:text-sm`}
        >
          PokeGrid {levelNumber}
        </div>
        <div id="right" className="text-blue-950/80">
          <TabRight className="h-7 w-7 sm:h-8 sm:w-8" />
        </div>
      </div>
    </>
  );
}

function TabLeft(props) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 0H0C4.51409 0 8.65406 2.20577 10.9423 5.8L33.5391 41.3C36.97 46.6913 43.2337 50 50 50V0Z"
        fill="currentColor"
      />
    </svg>
  );
}

function TabRight(props) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.43311e-06 0H50C45.4859 0 41.3459 2.20577 39.0577 5.8L16.4609 41.3C13.03 46.6913 6.76625 50 2.43311e-06 50V0Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function FriendGrid({ attempt }) {
  const [grid, globalStats] = useGameStore((state) => [
    state.grid,
    state.globalStats,
  ]);

  const rarity = calcRarity(attempt.answers, globalStats);
  const rarityDisplay = isNaN(rarity) ? 900 : rarity;

  return (
    <>
      <div className="-ml-4 -mr-4 flex justify-center">
        <div>
          <div className="flex">
            <div className="w-21 sm:-ml-10 sm:w-32"></div>
            <ColHeader item={grid.hItems[0]} />
            <ColHeader item={grid.hItems[1]} />
            <ColHeader item={grid.hItems[2]} />
          </div>
          <div className="flex items-center">
            <div>
              <RowHeader item={grid.vItems[0]} />
              <RowHeader item={grid.vItems[1]} />
              <RowHeader item={grid.vItems[2]} />
            </div>
            <div className="grid grid-cols-3 overflow-hidden rounded-xl border bg-white dark:border-gray-950 dark:bg-gray-800">
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={3} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={3} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={3} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 mt-6 sm:mb-4 md:mt-8">
        <div className="text-center text-sm font-semibold uppercase text-gray-600 dark:text-gray-300 sm:text-sm">
          Rarity Score
        </div>
        <div className="flex items-center justify-center ">
          <div className="relative min-w-[8rem] px-8 text-center text-5xl font-semibold leading-none tracking-tight sm:text-6xl">
            <CountUpRarity rarity={rarityDisplay} />
          </div>
        </div>
      </div>
    </>
  );
}

function FriendCell({ attempt, selectedTab, row, col }) {
  const [globalStats] = useGameStore((state) => [state.globalStats]);
  const [cardMode] = usePersistentStore((state) => [state.cardMode]);

  const storedCorrectGuessId = attempt.answers[row - 1][col - 1];
  const displayPokemon = pokemonList.find(
    (pokemon) => pokemon.id === storedCorrectGuessId
  );
  const correctAnswersPlayerMap =
    globalStats?.correctAnswersPlayerMap[row - 1][col - 1];
  const selectedPct =
    displayPokemon && correctAnswersPlayerMap
      ? (correctAnswersPlayerMap[displayPokemon.id] / globalStats?.completed) *
        100
      : 0.1;

  const scheme = rarityScheme(selectedPct);
  //  const bg = cardMode ? scheme.class : "bg-[#59d185]";
  const bg = "bg-slate-400";

  return (
    <div
      className={`flex h-21 w-21 items-center justify-center overflow-hidden border-slate-500 bg-cover bg-center ${
        col < 3 && "border-r"
      } ${
        row < 3 && "border-b"
      } ${bg} text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl`}
    >
      {displayPokemon && (
        <div className="overflow group relative flex h-full w-full items-center justify-center">
          {!isNaN(selectedPct) && (
            <div className="absolute right-0 top-0 flex h-4 items-center rounded-bl-lg bg-gray-900 pl-1.5 pr-1 text-xxs text-white opacity-95 sm:h-5 sm:pr-1.5 sm:text-xs md:h-6">
              {selectedPct < 1
                ? selectedPct.toFixed(1)
                : selectedPct.toFixed(0)}
              %
            </div>
          )}
          <PokemonImage
            pokemon={displayPokemon}
            className={`absolute p-4 pt-0 ${
              selectedTab == "no_spoilers" ? "opacity-80 contrast-0" : ""
            }`}
          />

          {selectedTab != "no_spoilers" && (
            <>
              {/* <div className="absolute bottom-0 left-0 right-0">
              {selectedTab == "my" && !undoUsed && (
                <div
                  className={`line-clamp-1 ${
                    undoModeActive ? "flex" : "hidden"
                  } h-24 items-center justify-center bg-gradient-to-b from-gray-900/0 from-0% to-gray-900/95 py-px text-xxs text-white sm:h-36 sm:text-xs sm:group-hover:flex md:h-48 md:text-sm`}
                >
                  <svg
                    className="mr-2 h-4 w-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 940 1000"
                  >
                    <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
                  </svg>{" "}
                  Undo
                </div>
              )}
            </div> */}
              <div className="absolute bottom-0 left-0 right-0">
                {/* <div className="line-clamp-1 hidden h-40 items-center justify-center bg-gradient-to-b from-gray-900/0 from-0% to-gray-900/95 py-px text-xxs text-white group-hover:flex  sm:text-xs md:text-sm">
              <svg
                className="mr-2 h-4 w-4 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 940 1000"
              >
                <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
              </svg>{" "}
              Undo
            </div> */}
                <div className="truncate whitespace-nowrap bg-gray-900 px-px py-px text-xxs text-white opacity-95 sm:text-xs">
                  {displayPokemon.display_name}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function ColHeader({ item }) {
  const type = item.type == "pokemon-type" ? "pokemon-type" : "award";

  return (
    <div className="flex h-16 w-21 items-center justify-center text-xs sm:h-20 sm:w-28 ">
      {type == "award" ? (
        <CategoryHeaderSmall item={item} pos="col" />
      ) : (
        <PokemonTypeImage
          typeId={item.id}
          className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
        />
      )}
    </div>
  );
}

function RowHeader({ item }) {
  const type = item.type == "pokemon-type" ? "pokemon-type" : "award";

  return (
    <div className="flex h-21 w-21 items-center justify-center text-xs  sm:-ml-10 sm:h-28 sm:w-32">
      {type == "award" ? (
        <CategoryHeaderSmall item={item} pos="row" />
      ) : (
        <PokemonTypeImage
          typeId={item.id}
          className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
        />
      )}
    </div>
  );
}

export default FriendDialog;
