import React from "react";
import { trackEvent, trackSelect } from "../lib/utils/analytics";
import useGameStore from "../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { Board } from "./Board";
import { Leaderboard } from "./Leaderboard";

export default function TabFrames(params) {
  const [selectedTab, setSelectedTab] = useGameStore(
    (state) => [state.selectedTab, state.setSelectedTab],
    shallow
  );

  const [noSpoilers, toggleNoSpoilers] = useGameStore((state) => [
    state.noSpoilers,
    state.toggleNoSpoilers,
  ]);

  return (
    <>
      <div className={`${selectedTab == "my" ? "" : "hidden"}`}>
        <Board selectedTab={noSpoilers ? "no_spoilers" : "my"} />
      </div>
      {/* <div className={`${selectedTab !== "no_spoilers" ? "hidden" : ""}`}>
        <Board selectedTab="no_spoilers" />
      </div> */}
      <div className={`${selectedTab !== "popular" ? "hidden" : ""}`}>
        <Board selectedTab="popular" />
      </div>
      <div className={`${selectedTab !== "leaderboard" ? "hidden" : ""}`}>
        <Leaderboard />
      </div>
    </>
  );
}
