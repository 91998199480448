import BasePage from "./BasePage";
import TabGroup from "../buttons/TabGroup";
import TabFrames from "../TabFrames";

function PageIndex() {
  return (
    <BasePage>
      <main className="mx-auto min-h-[34.625rem] min-w-min bg-slate-100 text-center sm:min-h-[41.375rem] md:min-h-[52.5625rem]">
        {/* <Hero /> */}
        <div className="mx-auto">
          <TabGroup />
          <TabFrames />
        </div>
        {/* {initError && <NetworkError onRetry={init} />} */}
      </main>
    </BasePage>
  );
}
export default PageIndex;
