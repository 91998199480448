import { useState } from "react";
import useGameStore from "../lib/store/GameStore";
import InviteButton from "./buttons/InviteButton";
import { trackEvent, trackSelect } from "../lib/utils/analytics";
import { toast, cssTransition } from "react-toastify";
import { useLeaderboard } from "./hooks/useLeaderboard";
import usePersistentStore from "../lib/store/PersistentStore";
import { gridLevelDateKey } from "../lib/utils/gridLevels";
import { calcRarity } from "../lib/utils/calcRarity";
import FriendDialog from "./modals/FriendDialog";
import { MiniGrid } from "./modals/PickHistoryDialog";
import { PokemonTypeImage } from "./PokemonTypeImage";

export function Leaderboard() {
  const [localHistory] = usePersistentStore((state) => [state.history]);
  const localAttempt = localHistory[gridLevelDateKey];
  const localAttemptComplete = localAttempt && localAttempt.guessesLeft == 0;

  const [user, leaderboardTab, setLeaderboardTab, setIsLoginOpen] =
    useGameStore((state) => [
      state.user,
      state.leaderboardTab,
      state.setLeaderboardTab,
      state.setIsRegisterOpen,
    ]);

  const { leaderboard, isLoading, isError } = useLeaderboard();

  function openLoginModal() {
    setIsLoginOpen(true);
  }

  function onTabClick(tab) {
    setLeaderboardTab(tab);

    trackEvent(`tab_${tab}`);
  }

  const buttonActive = "bg-slate-500 text-gray-100";
  const buttonInactive = "bg-slate-200 text-gray-700";

  function ErrorMessage() {
    return (
      <div className="mt-12">
        <div className="py-4 text-sm text-gray-500 dark:text-gray-400">
          <p className="mb-4">Error loading leaderboard.</p>
          <p>Please refresh to try again.</p>
        </div>
      </div>
    );
  }

  function LoadingMessage() {
    return <></>;
  }

  return (
    <div className="px-4">
      <div
        id="leaderboard-outer"
        className="relative mx-auto max-w-lg space-y-4 overflow-x-auto rounded-2xl bg-white px-3 py-4 shadow-md"
      >
        <div id="leaderboard-tabs" className="flex space-x-2 text-xs uppercase">
          <button
            onClick={() => onTabClick("friends")}
            className={`w-1/3 rounded-xl p-2 font-semibold uppercase ${
              leaderboardTab == "friends" ? buttonActive : buttonInactive
            }`}
          >
            Friends
          </button>
          <button
            onClick={() => onTabClick("members")}
            className={`w-1/3 rounded-xl p-2 font-semibold uppercase ${
              leaderboardTab == "members" ? buttonActive : buttonInactive
            }`}
          >
            Members
          </button>
          <button
            onClick={() => onTabClick("global")}
            className={`w-1/3 rounded-xl p-2 font-semibold uppercase ${
              leaderboardTab == "global" ? buttonActive : buttonInactive
            }`}
          >
            Global
          </button>
        </div>
        {isLoading ? (
          <LoadingMessage />
        ) : isError ? (
          <ErrorMessage />
        ) : (
          <>
            <div
              id="tab-friends"
              className={`${
                leaderboardTab == "friends" ? "" : "hidden"
              } space-y-4`}
            >
              <div
                id="leaderboard-inner"
                className="space-y-1.5 rounded-xl bg-slate-100 p-2"
              >
                {!user && (
                  <div className="items-center justify-center space-y-4 py-6 text-md font-normal text-gray-600">
                    <p>
                      {/* <button
                      onClick={openLoginModal}
                      className="font-medium text-red-600 hover:underline dark:text-red-500"
                    >
                      Sign in
                    </button> */}
                      <a
                        href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/account/login?utm_content=friends`}
                        className="font-medium text-red-600 hover:underline dark:text-red-500"
                      >
                        Sign in
                      </a>{" "}
                      to see your friends' scores.
                    </p>
                    <p>
                      Not registered?{" "}
                      <a
                        href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/account/register?utm_content=friends`}
                        className="font-medium text-red-600 hover:underline dark:text-red-500"
                      >
                        Create an account
                      </a>
                    </p>
                  </div>
                )}
                {user &&
                  leaderboard.friends.length + leaderboard.yetToPlay.length <=
                    1 && (
                    <div className="flex flex-col items-center justify-center space-y-4 px-4 py-6 text-md font-normal text-gray-700">
                      <p>
                        Share your friend code below and challenge your buddies!
                      </p>
                      <p>Friends will appear here once added</p>
                    </div>
                  )}

                {/* {user &&
                leaderboard.friends.length == 0 &&
                leaderboard.yetToPlay.length > 0 && (
                  <div className="flex flex-col items-center justify-center space-y-4 py-6 text-sm font-normal text-gray-600">
                    <p>No friends have played today's grid 😢</p>
                    <p>Check back later</p>
                  </div>
                )} */}

                {leaderboard.friends.length + leaderboard.yetToPlay.length >
                  1 &&
                  leaderboard.friends.map((attempt, index) => (
                    <FriendRow
                      key={attempt.id}
                      attempt={attempt}
                      rank={index + 1}
                    />
                  ))}

                {leaderboard.yetToPlay.length > 0 &&
                  leaderboard.friends.length + leaderboard.yetToPlay.length >
                    1 && (
                    <>
                      {/* <Divider label="Yet to play today" /> */}

                      {leaderboard.yetToPlay.map((user) => (
                        <YetToPlayRow key={user.id} user={user} />
                      ))}
                    </>
                  )}
              </div>
              {user && <AddFriends />}
            </div>
            <div
              id="tab-members"
              className={`${
                leaderboardTab == "members" ? "" : "hidden"
              } space-y-4`}
            >
              <div
                id="leaderboard-inner"
                className="space-y-1.5 rounded-xl bg-slate-100 p-2"
              >
                {localAttemptComplete && user == null && (
                  <>
                    <YetToRegisterRow localAttempt={localAttempt} />
                    <div className="h-2"></div>
                  </>
                )}
                {leaderboard?.myRank == null && user && (
                  <>
                    <YetToPlayRow user={user} />

                    <div className="h-2"></div>
                  </>
                )}
                {leaderboard?.myRank?.memberRank > 20 && (
                  <>
                    <LeaderboardRow
                      attempt={leaderboard.myRank}
                      rank={leaderboard.myRank.memberRank}
                    />

                    <div className="h-2"></div>
                  </>
                )}

                {user
                  ? leaderboard.member.map((attempt) => (
                      <FriendRow
                        key={attempt.id}
                        attempt={attempt}
                        rank={attempt.memberRank}
                      />
                    ))
                  : leaderboard.member.map((attempt) => (
                      <LeaderboardRow
                        key={attempt.id}
                        attempt={attempt}
                        rank={attempt.memberRank}
                      />
                    ))}
                <div className="px-2 pb-1 pt-2 text-left text-xs text-gray-600 sm:pt-3 ">
                  Leaderboard for registered and verified PokeGrid users
                </div>
              </div>
            </div>
            <div
              id="tab-global"
              className={`${
                leaderboardTab == "global" ? "" : "hidden"
              } space-y-4`}
            >
              <div
                id="leaderboard-inner"
                className="space-y-1.5 rounded-xl bg-slate-100 p-2"
              >
                {localAttemptComplete && user == null && (
                  <>
                    <YetToRegisterRow localAttempt={localAttempt} />
                    <div className="h-2"></div>
                  </>
                )}
                {leaderboard?.myRank == null && user && (
                  <>
                    <YetToPlayRow user={user} />

                    <div className="h-2"></div>
                  </>
                )}
                {leaderboard?.myRank?.overallRank > 20 && (
                  <>
                    <LeaderboardRow
                      attempt={leaderboard.myRank}
                      rank={leaderboard.myRank.overallRank}
                    />

                    <div className="h-2"></div>
                  </>
                )}
                {leaderboard.overall.map((attempt) => (
                  <LeaderboardRow
                    key={attempt.id}
                    attempt={attempt}
                    rank={attempt.overallRank}
                  />
                ))}
                <div className="px-2 pb-1 pt-2 text-left text-xs text-gray-600 sm:pt-3 ">
                  All PokeGrid users (registered and anonymous)
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Divider({ label }) {
  return (
    <div className="relative flex items-center py-3">
      <div className="flex-grow border-t border-gray-300" />
      <span className="mx-4 flex-shrink text-xs font-medium uppercase text-gray-400">
        {label}
      </span>
      <div className="flex-grow border-t border-gray-300" />
    </div>
  );
}

function AddFriends() {
  const [friendCode, setFriendCode] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { invalidate } = useLeaderboard();

  const bounce = cssTransition({
    enter: "animate-toast-in",
    exit: "animate-toast-out",
    collapse: false,
    // collapseDuration: 0,
  });

  function handleInputChange(event) {
    setFriendCode(event.target.value);
  }

  const [user] = useGameStore((state) => [state.user]);

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitting(true);
    const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/friend/add`;

    const values = {
      code: friendCode,
    };
    console.log("payload", values);
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          toast(data.errors, {
            position: "top-center",
            transition: bounce,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            type: "error",
            theme: "light",
            pauseOnFocusLoss: false,
          });

          setFriendCode("");
        } else {
          toast(data.success, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            type: "success",
            theme: "light",
          });

          invalidate();
        }
        console.log(data);
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <div
      id="friends"
      className="space-y-4 rounded-xl bg-slate-100 px-3 py-4 sm:px-4"
    >
      <h2 className="px-1 text-left text-xs font-semibold uppercase text-gray-600 sm:text-sm">
        Add Friends
      </h2>
      <form onSubmit={handleSubmit} action="#" method="POST">
        <label
          htmlFor="friend-code"
          className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Enter friend code
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="h-4 w-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            onChange={handleInputChange}
            value={friendCode}
            type="text"
            id="friend-code"
            className={`block w-full rounded-lg border border-gray-300 bg-white p-3.5 pl-10 sm:p-4 sm:pl-10 sm:text-sm ${
              friendCode.length ? "font-medium uppercase" : ""
            } text-gray-900  focus:outline-none focus:ring-2 focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500`}
            placeholder="Enter friend code"
            maxLength={6}
            pattern="^[A-Za-z0-9]{6}$"
            required
          />
          <button
            type="submit"
            disabled={friendCode.length !== 6}
            className="absolute bottom-2.5 right-2.5 rounded-lg bg-gray-400 px-4 py-2 text-xs font-medium uppercase text-white focus:outline-none focus:ring-4 focus:ring-gray-300 enabled:bg-green-600 enabled:hover:bg-green-800 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            Add <span className="hidden sm:inline-block">friend</span>
          </button>
        </div>
      </form>
      <div className="flex items-center justify-start space-x-2 pl-1 text-sm">
        <div>
          Your friend code:
          <span className="ml-1.5 rounded-lg bg-white px-2 py-2 text-base font-semibold">
            {user?.friendCode}
          </span>
        </div>
        <InviteButton code={user?.friendCode} />
      </div>
    </div>
  );
}

function YetToPlayRow({ user }) {
  return (
    <div className="flex w-full items-center justify-between rounded-full bg-white px-2 py-1 opacity-50 sm:px-3 sm:py-2">
      <div className="flex items-center ">
        <div
          id="rank"
          className={`mr-3 flex h-7 w-7 items-center justify-center rounded-full text-sm font-semibold tracking-tight sm:mr-4 sm:h-8 sm:w-8`}
        >
          -
        </div>
        <div id="name" className="flex items-center">
          <PokemonTypeImage
            type={user.favType}
            alt={`${user.displayName} avatar`}
            className="mr-2 h-6 w-6 rounded-full sm:mr-3 sm:h-7 sm:w-7"
          />

          <div className="flex flex-col items-start">
            <div className="line-clamp-1 text-ellipsis text-left text-sm font-medium text-gray-800 sm:text-base">
              {user.displayName}
            </div>
            {/* <div className="text-xs font-normal text-gray-500">
              {attempt?.user?.displayName || "Anon"}
            </div> */}
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <div className="hidden sm:block">{/* <MiniGrid /> */}</div>
        <div
          id="rarity"
          className="ml-2 mr-3 flex h-9 w-20 items-center justify-end rounded-full text-sm font-medium text-gray-700 sm:w-28 sm:text-base"
        >
          Yet to play
        </div>
      </div>
    </div>
  );
}

function YetToRegisterRow({ localAttempt }) {
  const [globalStats] = useGameStore((state) => [state.globalStats]);
  const rarity = calcRarity(localAttempt.correctGuesses, globalStats);

  return (
    <div className="user-row group flex w-full items-center justify-between rounded-full bg-slate-600  px-2 py-1 sm:px-3 sm:py-2">
      <div className="flex items-center ">
        <div
          id="rank"
          className="mr-3 flex h-7 w-7 min-w-[1.75rem] items-center justify-center rounded-full bg-slate-500 text-sm font-semibold tracking-tight text-slate-100 sm:mr-4 sm:h-8 sm:w-8"
        >
          ?
        </div>
        <div id="name" className="flex items-center">
          <div className="mr-2 h-6 w-6 min-w-[1.5rem] rounded-full bg-gray-400 sm:mr-3 sm:h-7 sm:w-7"></div>
          <div className="line-clamp-1 text-ellipsis text-left text-sm font-normal text-gray-800 group-[.user-row]:text-slate-100 sm:text-sm">
            <a
              href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/account/login?utm_content=leaderboard`}
              className="font-medium underline"
            >
              Sign in
            </a>{" "}
            to{" "}
            <span className="hidden sm:inline-block">see your daily rank</span>
            <span className="sm:hidden">reveal rank</span>
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <div
          id="rarity"
          className="mr-3 flex h-9 w-7 items-center justify-end rounded-full text-sm font-semibold text-gray-700 group-[.user-row]:text-slate-100 sm:text-base"
        >
          {rarity >= 100 ? rarity : rarity.toFixed(1)}
        </div>
      </div>
    </div>
  );
}

function FriendRow({ attempt, rank }) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackEvent("leaderboard_friend_view");
    trackSelect({
      contentType: "button",
      itemId: "view_friend",
    });
  }

  return (
    <>
      <button
        onClick={openModal}
        className="flex w-full items-center rounded-full transition-transform hover:scale-[1.01] active:scale-[.98]"
      >
        <LeaderboardRow attempt={attempt} rank={rank} showGrid={true} />
      </button>
      <FriendDialog isOpen={isOpen} closeModal={closeModal} attempt={attempt} />
    </>
  );
}

function LeaderboardRow({ attempt, rank, showGrid = false }) {
  const [user] = useGameStore((state) => [state.user]);

  const rankBg =
    rank == 1
      ? "bg-amber-400 text-amber-700 sm:text-sm"
      : rank == 2
      ? "bg-slate-300 text-slate-700 sm:text-sm"
      : rank == 3
      ? "bg-yellow-500 text-yellow-800 sm:text-sm"
      : "text-gray-500 group-[.user-row]:text-slate-100";

  const rarity = parseFloat(attempt.rarity);

  return (
    <>
      <div
        className={`${
          user && attempt.user?.id == user.id
            ? "user-row bg-orange-600"
            : "bg-white"
        } group flex w-full items-center justify-between rounded-full  px-2 py-1 sm:px-3 sm:py-2 ${
          attempt.user ? "" : "opacity-50"
        }`}
      >
        <div className="flex items-center ">
          <div
            id="rank"
            className={`mr-3 flex h-7 w-7 min-w-[1.75rem] items-center justify-center rounded-full font-semibold tracking-tight sm:mr-4 sm:h-8 sm:w-8 ${rankBg} group-[.user-row]:text-gray-800 ${
              rank < 1000 ? "text-sm" : "text-xs"
            }`}
          >
            {rank}
          </div>
          <div id="name" className="flex items-center">
            {attempt?.user?.favType ? (
              <PokemonTypeImage
                type={attempt.user.favType}
                alt={`${attempt.user.displayName} avatar`}
                className="mr-2 h-6 w-6 rounded-full sm:mr-3 sm:h-7 sm:w-7"
              />
            ) : (
              <div className="mr-2 h-6 w-6 min-w-[1.5rem] rounded-full bg-gray-200 sm:mr-3 sm:h-7 sm:w-7"></div>
            )}

            {attempt?.user?.displayName ? (
              <div className="line-clamp-1 text-ellipsis text-left text-sm font-medium text-gray-800 group-[.user-row]:text-slate-100 sm:text-base">
                {attempt.user.displayName}
              </div>
            ) : (
              <div
                className="h-3 max-w-[160px] rounded-full bg-gray-200 dark:bg-gray-700"
                style={{ width: getRandomNumber(rank, 70, 140) + "px" }}
              ></div>
            )}
          </div>
        </div>

        <div className="flex items-center">
          {showGrid && (
            <div className="mr-2 w-5">
              <MiniGrid />
            </div>
          )}
          <div
            id="rarity"
            className="ml-2 mr-3 flex h-9 w-9 items-center justify-end rounded-full text-sm font-semibold text-gray-700 group-[.user-row]:text-slate-100 sm:text-base"
          >
            {rarity >= 100 ? rarity : rarity.toFixed(1)}
          </div>
        </div>
      </div>
    </>
  );
}

function getRandomNumber(seed, min, max) {
  // Seed the random number generator with the provided seed value
  const seededRandom = function (seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  };

  // Generate a random number based on the seed and adjust it to the desired range
  const randomNumber = Math.floor(seededRandom(seed) * (max - min + 1)) + min;

  return randomNumber;
}
